import React from 'react';
import { graphql } from 'gatsby';

import { Layout } from '../components/index';
import { Link, safePrefix, markdownify, classNames, formatGatsbyDate } from '../utils';

export default class EpisodeList extends React.Component {
	render() {
		return (
			<Layout {...this.props}>
				<div id="wrapper">
					<section id="main" className="wrapper">
						<div className="inner">
							<h1 className="major">All Episodes</h1>
						</div>
						<section className="wrapper spotlights">
							{this.props.data.allSitePage.nodes.map(({ context }, index) => (
								<section key={index} className="episode-list-item">
									{context.frontmatter.thumbnail && (
										<Link to={safePrefix(context.url)} className="image">
											<img
												src={safePrefix(context.frontmatter.thumbnail)}
												alt={context.frontmatter.thumbnail_alt}
												data-position={context.frontmatter.thumbnail_position}
											/>
										</Link>
									)}
									<div className="content">
										<h2>
											#{context.frontmatter.number} - {context.frontmatter.title}
										</h2>
										{context.frontmatter.date && (
											<span>{formatGatsbyDate(context.frontmatter.date)}</span>
										)}
										{markdownify(context.frontmatter.excerpt)}
										<ul className="actions">
											<li>
												<Link to={safePrefix(context.url)} className="button">
													Listen now
												</Link>
											</li>
										</ul>
									</div>
								</section>
							))}

							<div className="inner">
								{this.props.pageContext.numberOfPages > 1 && (
									<div className="pagination">
										<Link
											to={this.props.pageContext.previousPagePath}
											className={classNames(
												'button',
												!this.props.pageContext.previousPagePath && 'disabled'
											)}
										>
											&lt; Newer
										</Link>
										<span>
											Page {this.props.pageContext.humanPageNumber} of{' '}
											{this.props.pageContext.numberOfPages}
										</span>
										<Link
											to={this.props.pageContext.nextPagePath}
											className={classNames(
												'button',
												!this.props.pageContext.nextPagePath && 'disabled'
											)}
										>
											Older &gt;
										</Link>
									</div>
								)}
							</div>
						</section>
					</section>
				</div>
			</Layout>
		);
	}
}

export const pageQuery = graphql`
	query($skip: Int!, $limit: Int!) {
		allSitePage(
			limit: $limit
			skip: $skip
			sort: { fields: context___frontmatter___date, order: DESC }
			filter: { context: { frontmatter: { type: { eq: "episode" } } } }
		) {
			nodes {
				context {
					url
					frontmatter {
						date
						excerpt
						thumbnail
						thumbnail_alt
						thumbnail_position
						title
						number
					}
				}
			}
		}
	}
`;
